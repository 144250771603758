/**
 * Handles form submissions via Ajax using jQuery Form Plugin (https://jquery-form.github.io/form/).
 * Expects that the forms are using Bootstrap 4 layout.
 *
 * @param {jQuery} $form
 * @param {jQuery} $successContainer
 */
function handleFormSubmission($form, $successContainer) {
    if (!$form.length) return;

    let $submitButton = $form.find('input[name=submit], input[type=submit]'); 
    let submitButtonInitialText = $submitButton.val();
    
    $form.submit(function(event) {
        event.preventDefault();
    
        if(performPreValidation($form)) {

            // ERRORS

        } else {
            
            grecaptcha.ready(function() {
                grecaptcha.execute('6LeB-EUqAAAAABTY-wow-GrCX2DUJChXb0k4fb-u', {action: 'submit'}).then(function(token) {
                    // Add the "g-recaptcha-response" field with the token to the form data.
                    let $gRecaptchaResponse = $('<input type="hidden" name="g-recaptcha-response">').val(token);
                    $form.append($gRecaptchaResponse);
                    $form.ajaxSubmit({
                        success: success,
                        beforeSubmit: function(arr, $form) {
                            let sendingText = '...';
                            $form.find('.btn').prop('disabled', true).text(sendingText).val(sendingText);
                        }
                    });
                });
            });
        }
    });
    /**
     * @param {object} response
     * @param {boolean} response.result
     * @param {object} response.validationErrors Field name => error message.
     * @param {string} statusText
     * @param {object} xhr
     * @param {object} $form
     */

    function resetForm($form) {
        $form.find('.btn').prop('disabled', false).text(submitButtonInitialText).val(submitButtonInitialText);
    }

    function setValid($field) {
        $field
            .addClass('is-valid')
            .removeClass('is-invalid')
            .parent()
            .find('.invalid-feedback')
            .remove();
    }

    function setInValid($field) {
        $field
            .addClass('is-invalid')
            .parent()
            .find('.invalid-feedback')
            .remove();
        $field.parent().append('<div class="invalid-feedback">This field is required</div>')
        $field.on('change', function(){
            // alert($(this).parent().parent().closest('form').attr('id'));
            peformFieldValidation($(this).parent().parent().closest('form'),$(this),$(this).attr('id'));
        });
    }

    function validateEmail(email) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);    
    }

    /* Pre-validation before submission */
    function performPreValidation($form) {
   
        resetForm($form); 

        let getAllRequiredFields = $form.find('.required');

        getAllRequiredFields.each(function() {
            let currentFieldName = $(this).attr('for');
            let currentField = $form.find('#'+currentFieldName);
            
            peformFieldValidation($form, currentField, currentFieldName);

        });

        let errors = $form.find('.invalid-feedback');
       
        if(errors.length) { 
            return true;
        } else {
            return false; // no errors
        }
    }


    function peformFieldValidation($form, $field, $name) {

        if($field && $name == "frm-state") {
            let country = $form.find('#frm-country').val();
            if(country === "United States") {
                if($field.val() && $field.val().length > 1) {
                    setValid($field);
                } else {
                    setInValid($field);
                }
            } else {
                setValid($field);
            }
            return;
        }

        if($field && $name == "frm-province") {
            let country = $form.find('#frm-country').val();
            if(country === "Canada") {
                if($field.val().length > 2) {
                    setValid($field);
                } else {
                    setInValid($field);
                }
            } else {
                setValid($field);
            }
            return;
        }

        if($field && $name == "frm-email") {
            if(validateEmail($field.val())) {
                setValid($field);
            } else {
                setInValid($field);
            }
            return;
        }


        if($field && $field.val()) {
            setValid($field);
        } else {
            setInValid($field);
        }

        return;

    }


    function success(response, statusText, xhr, $form) {
        
        resetForm($form);
        
        const responseJson = JSON.parse(response);

        if (responseJson.errors) {
            console.log(responseJson.errors);
            $form.addClass('was-validated');
            $form.find('.invalid-feedback').remove();
            $form.find('.is-invalid').removeClass('is-invalid');

            for (let field in responseJson.errors) {
                
                let errorMessage = responseJson.errors[field][0];
               
                let $inputElement = $form.find('[name="'+field+'"]');
                if (!$inputElement.length) {
                    $inputElement = $form.find('[name="'+field+'[]"]').first();
                    if (!$inputElement.length) continue;
                }
                $inputElement.addClass('is-invalid');
                $inputElement[0].setCustomValidity(errorMessage);

                let $formGroup = $inputElement.closest('.form-group');
                $formGroup.append('<div class="invalid-feedback">'+errorMessage+'</div>');
                $inputElement.on('click', function() {
                    $inputElement.removeClass('is-invalid');
                    this.setCustomValidity('');
                    $formGroup.find('.invalid-feedback').remove();
                })
            }
        }
        else if (responseJson.success == true) {
            // In some cases you would like to reuse a form again, so I'm hiding and resetting it instead of removing.
            $form.hide();
            $form[0].reset();
            $successContainer.fadeIn();

            dataLayer.push({ 'event': 'formSubmission', 'formName': $form[0].id });
            dataLayer.push({
                'event': 'formTracking',
                'category': 'form',
                'action': 'submit',
                'label': '$form[0].id'
            });

            if (typeof fathom !== 'undefined') {
                // Catch all tracking
                window.fathom.setSite(fathomSiteIds.catchAll)
                fathom.trackEvent($form[0].id);

                // Individual site tracking
                window.fathom.setSite(fathomSiteIds[currentLanguageId])
                fathom.trackEvent($form[0].id);
            }
        }
    }
}

document.addEventListener('DOMContentLoaded', function() {
    let formsIds = [
        'ri', // Request Information form
        'ae', // Ask the Expert big form
        'aoe', // Ask our Experts popup form
        'so', // Sales Office form
        'rq' // Request a Quote form
    ];
    const formIdPostfix = '-form';
    const successContainerPostfix = '-success-submission';

    for (let formId of formsIds) {
        handleFormSubmission($('#' + formId + formIdPostfix), $('#' + formId + successContainerPostfix));
    }
});


var formIds = $("#aoe-form, #rq-form, #ae-form, #ri-form");

// close clicking x
$("[data-dismiss=modal]").on('click', function(e){
    var modalContent = $(this).parent().parent();    
    resetModal(modalContent);
});

// close clicking outside modal
$(".modal.fade").on('click', function(e){
    var modalContent = $(this).parent().parent();
    if(e.target != this) { return; } else {
        resetModal(modalContent);
    }
});

function resetModal(modalContent) {
    setTimeout(function(){
        formIds.removeClass('was-validated');
        formIds.find('input, textarea, select').removeClass('is-invalid');
        formIds.find('.btn').prop('disabled', false);
        modalContent.find('form').show();
        modalContent.find('.form-success-submission').hide();
        modalContent.find("#frm-state")
          .attr('required','')
          .parent()
          .show();
      },1000);
}

formIds && formIds.attr("novalidate", "novalidate");

//default to uk for 'en'
document.addEventListener('DOMContentLoaded', function() {
    var segment1 = window.location.pathname.split('/')[1];
    console.log('segment1: ' + segment1);
    if (segment1 === 'en') {
        var countrySelect = document.getElementById("frm-country");
        console.log('countrySelect.value: ' + countrySelect.value);

        if (countrySelect) {
            console.log('switch to uk');
            countrySelect.value = "United Kingdom"; 
            
            // Trigger change event
            var event = new Event("change", { bubbles: true });
            countrySelect.dispatchEvent(event);
        }
    }  
}); 

formIds && formIds.find("#frm-country").on('change', function(){
    var formParent = $(this).parent().parent();
    var formWrapper = $(this).closest('form'); 

    let fieldState = formWrapper.find("#frm-state");
    let fieldProvince =  formWrapper.find("#frm-province");
    fieldState && fieldState.removeClass('is-invalid').removeClass('is-valid');
    fieldProvince && fieldProvince.removeClass('is-invalid').removeClass('is-valid');

    if($(this).val() == "US" || $(this).val() == "United States") {
     
        formWrapper.find("#frm-province")  
            .removeAttr('required')
            .val('')
            .parent()
            .addClass('d-none')
            .hide();
        formParent.find("#frm-state")
            .attr('required','')
            .val('')
            .parent()
            .removeClass('d-none')
            .show();

        formWrapper.find('label[for="frm-city"]').addClass('required');
     
        formWrapper.find("#frm-city")
            .attr('required','')
       
 
        formParent.find('label[for="frm-zip"]')
            .text('Zip Code');

        
        if(formParent.parent().attr('id') == "ae-form" || formParent.parent().attr('id') == "ri-form") {
            formParent
                .find("#frm-zip, #frm-country")
                .parent()
                .attr('class','form-group col-sm-6 col-md-4');
        }
    } else if($(this).val() == "CAN" || $(this).val() == "Canada") {
        console.log('canada selected');

        var cityInput = formWrapper.find("#frm-city");
        if (cityInput.length) {
  
            formWrapper.find('label[for="frm-city"]').addClass('required');
        } else {
            console.error('frm-city not found within formWrapper');
        }

        formWrapper.find("#frm-province")
            .attr('required','')
            .val('')
            .parent()
            .removeClass('d-none')
            .show();
        formParent.find("#frm-state")
            .removeAttr('required')
            .val('')
            .parent()
            .addClass('d-none')
            .hide();
        

    } else {
        console.log('not us or canada selected');
        formWrapper.find('label[for="frm-city"]').removeClass('required');
     
        formWrapper.find("#frm-city")
            .removeAttr('required')
   
        formParent.find('label[for="frm-zip"]')
            .removeClass('required')
            .text('Postal Code');

        formWrapper.find("#frm-province")  
            .parent()
            .addClass('d-none')
            .hide();
            
        formParent.find("#frm-state")
            .removeAttr('required')    
            .parent()
            .hide()
            .find('input')
            .removeAttr('required')
            .focus()
            .val('')
            .blur();
            
        if(formParent.parent().attr('id') == "ae-form" || formParent.parent().attr('id') == "ri-form") {
            formParent
                .find("#frm-zip, #frm-country")
                .parent()
                .attr('class','form-group col-sm-6 col-md');
        }

     
    }
});

formIds && formIds
    // .attr('novalidate', 'novalidate')
    .find("#frm-country").trigger('change');

// Add document title to specific forms
$("#aoe-form, #rq-form, #ae-form, #ri-form").find('input[name="rct_prod"]').val(document.title);